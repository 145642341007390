export const baseUrl_img = 'https://qiniu.haidapu.com/' // 图片基地址
// export const baseUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.3.111:8006/' : 'http://haidapu.com:8006/'
// export const baseUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.3.111:8006/' : 'http://10.75.5.161:8006/'
export const baseUrl = process.env.NODE_ENV === 'development' ? 'api/' : 'http://www.haidapu.com/api/'


// 游客留言
export const sendMessage = 'base/message/save'

// 新闻列表
export const getNews = 'base/news/page'