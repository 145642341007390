import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/reset.css'
import MetaInfo from 'vue-meta-info'
import 'element-ui/lib/theme-chalk/index.css'
import { Pagination } from 'element-ui'
import 'animate.css'
Vue.config.productionTip = false
Vue.use(MetaInfo)
Vue.component(Pagination.name, Pagination);
new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
