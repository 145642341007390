<template>
  <div class="home">
    <div class="banner">
      <img src="@/assets/home/banner.jpg">
    </div>
    <div class="title">
      <div class="title-wrapper animate__animated" :class="animateShow.show1 ? 'animate__fadeInUp' : ''">
        <p class="cn">关于公司</p>
        <p class="en">ABOUT COMPANY</p>
      </div>
    </div>
    <div class="about animate__animated" :class="animateShow.show1 ? 'animate__fadeInUp' : ''">
      <div class="about-wrapper">
        <div class="about-video" >
          <video loop="loop" controls="controls" autoplay muted>
            <source src="@/assets/video.mp4" type="video/mp4">
          </video>
        </div>
        <div class="about-info">
          <div class="info-content">
            <p>海多普，城市管理信息服务专家。</p>
            <p>浙江海多普之星智能技术有限公司是一家致力于将现代信息技术在城市管理领域的创新应用与深度融合的企业。公司创立于2010 年，成长于杭州市高新技术产业开发区（滨江区）物联网街区，现有注册资金1000万元，员工200余人。</p>
            <p>公司专注于城市管理信息服务，以“专业、专注、共享、创新”的服务理念，为政府部门和合作伙伴提供智慧（数字）城管、市容环卫、垃圾分类、综合执法等行业相关的信息化解决方案。经过十余年的发展壮大，我司在湖州、金华、苏州、温州、阜阳、富阳、海宁等地设立了分公司，形成了立足浙江、辐射华东的营销网络和服务体系。</p>
          </div>
          <div class="info-btn">
            <a @click="toLink('/about')">了解更多</a>
            <!-- <a>联系我们</a> -->
          </div>
        </div>
      </div>
    </div>
    <div class="title">
      <div class="title-wrapper animate__animated" :class="animateShow.show2 ? 'animate__fadeInUp' : ''">
        <p class="cn">主营业务</p>
        <p class="en">MAIN BUSINESS</p>
      </div>
    </div>
    <div class="business">
      <div class="business-wrapper animate__animated" :class="animateShow.show2 ? 'animate__fadeInUp' : ''">
        <div class="left" @click="toProduct(1)">
          <img class="bgImg" src="@/assets/home/b_img1.jpg">
          <div class="label">
            <img src="@/assets/home/b_icon1.png">
            <span>智慧城管</span>
          </div>
        </div>
        <div class="right">
          <div class="card" @click="toProduct(2)">
            <img class="bgImg" src="@/assets/home/b_img2.jpg">
            <div class="label">
              <img src="@/assets/home/b_icon2.png">
              <span>垃圾治理</span>
            </div>
          </div>
          <div class="card" @click="toProduct(3)">
            <img class="bgImg" src="@/assets/home/b_img3.jpg">
            <div class="label">
              <img src="@/assets/home/b_icon3.png">
              <span>智慧环卫</span>
            </div>
          </div>
          <div class="card" @click="toProduct(4)">
            <img class="bgImg" src="@/assets/home/b_img4.jpg">
            <div class="label">
              <img src="@/assets/home/b_icon4.png">
              <span>智慧公厕</span>
            </div>
          </div>
          <div class="card" @click="toProduct(5)">
            <img class="bgImg" src="@/assets/home/b_img5.jpg">
            <div class="label">
              <img src="@/assets/home/b_icon5.png">
              <span>智能AI分析</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="title">
      <div class="title-wrapper animate__animated" :class="animateShow.show3 ? 'animate__fadeInUp' : ''">
        <p class="cn">我们的优势</p>
        <p class="en">ADVANTAGE</p>
      </div>
    </div>
    <div class="advantage">
      <div class="advantage-wrapper animate__animated" :class="animateShow.show3 ? 'animate__fadeInUp' : ''">
        <div class="adv-item" @click="toLink('/about')">
          <img src="@/assets/home/adv_1.png">
          <p>依托认证 茁壮成长</p>
        </div>
        <div class="adv-item" @click="toLink('/about')">
          <img src="@/assets/home/adv_2.png">
          <p>自主研发 创业创新</p>
        </div>
        <div class="adv-item" @click="toLink('/about')">
          <img src="@/assets/home/adv_3.png">
          <p>聚焦行业 共谋发展</p>
        </div>
      </div>
    </div>
    <div class="title">
      <div class="title-wrapper animate__animated" :class="animateShow.show4 ? 'animate__fadeInUp' : ''">
        <p class="cn">新闻动态</p>
        <p class="en">NEWS DYNAMIC</p>
      </div>
    </div>
    <div class="news animate__animated" :class="animateShow.show4 ? 'animate__fadeInUp' : ''">
      <img class="prev-btn" src="@/assets/about/prev.png">
      <div class="news-wrapper">
        <swiper class="swiper" :options="swiperOption" ref="swiperBox">
          <swiper-slide v-for="(item, i) in newsList" :key="i" class="slide-box">
            <div class="card" @click="openNews(item.url)">
              <img :src="item.image">
              <div class="text">
                <p class="text-title">{{item.title}}</p>
                <p class="text-info">{{item.brief}}</p>
              </div>
            </div>
          </swiper-slide>
          <!-- <div class="card">
            <img src="@/assets/home/news1.png">
            <div class="text">
              <p class="text-title">巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉</p>
              <p class="text-info">巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉</p>
            </div>
          </div>
          <div class="card">
            <img src="@/assets/home/news2.png">
            <div class="text">
              <p class="text-title">巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉</p>
              <p class="text-info">巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉</p>
            </div>
          </div>
          <div class="card">
            <img src="@/assets/home/news3.png">
            <div class="text">
              <p class="text-title">巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉</p>
              <p class="text-info">巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉</p>
            </div>
          </div> -->
        </swiper>
      </div>
      <img class="next-btn" src="@/assets/about/next.png">
      <div class="more-btn">
        <a @click="toLink('/news')">更多新闻</a>
      </div>
    </div>
    <Toast ref="myToast"></Toast>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'
import request from '@/api/http'
import { getNews } from '@/api/urls'
import Toast from '@/components/Toast'
export default {
  name: 'Home',
  metaInfo: {
    title: '智慧城管|垃圾治理|垃圾分类|智慧环卫|智慧公厕|智能AI分析-海多普-首页',
    meta: [
      {
        name: 'keyWords',
        content: '智慧城管,垃圾治理,垃圾分类,智慧环卫,智慧公厕,智能AI分析'
      },
      {
        name: 'description',
        content: '海多普，城市管理信息服务专家'
      }
    ]
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        navigation: {
          nextEl: '.next-btn',
          prevEl: '.prev-btn'
        }
      },
      newsList: [],
      size: 10,
      page: 1,
      animateShow: {
        show1: true,
        show2: false,
        show3: false,
        show4: false
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    Toast
  },
  created() {
    this.getData()
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll, true)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    async getData() {
      let res = await request.get(getNews, {
        params: {
          page: this.page,
          limit: this.size
        }
      })
      if (!res) return
      if (res.code === 200) {
        this.newsList = res.data.list
      } else {
        this.$refs.myToast.show(1, res.msg)
      }
    },
    toLink(path) {
      this.$router.push(path)
    },
    toProduct(type) {
      this.$router.push({
        name: 'Solution',
        params: {
          type
        }
      })
    },
    openNews(link) {
      window.open(link, '_blank')
    },
    handleScroll(e) {
      var scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop
      if (scrollTop >= 400) {
        this.animateShow.show2 = true
      }
      if (scrollTop >= 1100) {
        this.animateShow.show3 = true
      }
      if (scrollTop >= 1400) {
        this.animateShow.show4 = true
      }
      console.log(scrollTop)
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  .animate__animated {
    opacity: 0;
  }
  .banner {
    width: 100%;
    overflow: hidden;
    margin-bottom: 60px;
    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
  .title {
    height: 60px;
    margin-bottom: 50px;
    .title-wrapper {
      width: 1200px;
      height: 60px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .cn {
        font-size: 30px;
      }
      .en {
        font-size: 16px;
        color: #99acbc;
        font-weight: 600;
      }
    }
  }
  .about {
    height: 340px;
    margin-bottom: 70px;
    .about-wrapper {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      .about-video {
        width: 600px;
        height: 340px;
        overflow: hidden;
        border-radius: 10px;
        video {
          width: 100%;
          height: 100%;
        }
      }
      .about-info {
        width: calc(100% - 600px);
        height: 100%;
        padding-left: 24px;
        // padding-top: 20px;
        position: relative;
        .info-content {
          line-height: 30px;
          p {
            text-indent: 2em;
            &:nth-child(1) {
              font-weight: bold;
            }
          }
        }
        .info-btn {
          position: absolute;
          bottom: 0;
          a {
            width: 116px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #194898;
            color: #FFFFFF;
            border-radius: 4px;
            margin-right: 30px;
            float: left;
            cursor: pointer;
          }
        }
      }
    }
  }
  .business {
    height: 599px;
    margin-bottom: 64px;
    .business-wrapper {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .left {
        position: relative;
        width: 270px;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 1s;
        .bgImg {
          position: absolute;
          width: 270px;
          height: 100%;
          transition: all 1s;
        }
        &:hover {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.66);
          .bgImg {
            transform: scale(1.2);
          }
        }
        .label {
          width: 122px;
          height: 117px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          position: relative;
          z-index: 99;
          img {
            display: block;
            width: 80px;
            height: 57px;
          }
          span {
            color: #FFFFFF;
            font-size: 30px;
          }
        }
      }
      .right {
        width: 912px;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        .card {
          width: 447px;
          height: 292px;
          overflow: hidden;
          position: relative;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 1s;
          .bgImg {
            position: absolute;
            width: 447px;
            height: 292px;
            transition: all 1s;
          }
          &:hover {
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.66);
            .bgImg {
              transform: scale(1.2);
            }
          }
          .label {
            width: 152px;
            height: 140px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            position: relative;
            z-index: 99;
            img {
              display: block;
              width: 80px;
              height: 80px;
            }
            span {
              color: #FFFFFF;
              font-size: 30px;
            }
          }
        }
      }
    }
  }
  .advantage {
    height: 208px;
    margin-bottom: 129px;
    .advantage-wrapper {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      .adv-item {
        position: relative;
        height: 100%;
        cursor: pointer;
        &::after {
          content: ' ';
          position: absolute;
          width: 36px;
          height: 4px;
          background-color: #666666;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        img {
          display: block;
          width: 182px;
          height: 175px;
        }
        p {
          text-align: center;
          color: #666666;
          font-size: 16px;
        }
      }
    }
  }
  .news {
    height: 600px;
    position: relative;
    .prev-btn {
      position: absolute;
      left: 280px;
      top: 210px;
      cursor: pointer;
    }
    .next-btn {
      position: absolute;
      right: 280px;
      top: 210px;
      cursor: pointer;
    }
    .news-wrapper {
      width: 1200px;
      height: 470px;
      margin: 0 auto;
      // display: flex;
      // justify-content: space-between;
      .slide-box {
        width: 380px !important;
        height: 470px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .card {
        width: 336px;
        height: 428px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        // overflow: hidden;
        cursor: pointer;
        img {
          display: block;
          width: 336px;
          height: 253px;
          object-fit: cover;
        }
        .text {
          padding: 17px;
          margin-top: 10px;
          .text-title {
            font-size: 20px;
            line-height: 20px;
            color: #333333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .text-info {
            font-size: 16px;
            color: #666666;
            line-height: 27px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            margin-top: 15px;
            text-indent: 2em;
          }
        }
      }
    }
    .more-btn {
      width: 100%;
      text-align: center;
      margin-top: 30px;
      a {
        width: 116px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #666666;
        background-color: #FFFFFF;
        color: #666666;
        border-radius: 4px;
        cursor: pointer;
        margin: 0 auto;
      }
    }
  }
}
</style>
