<template>
  <div id="app">
    <div class="header">
      <div class="header-wrapper">
        <div class="logo"><img src="@/assets/logo.png" alt="海多普" title="海多普"></div>
        <ul class="nav">
          <li :class="currRoute === 'Home' ? 'active' : ''" @click="toLink('/')">首页</li>
          <li :class="currRoute === 'Solution' ? 'active' : ''" @click="toLink('/solution')">解决方案</li>
          <li :class="currRoute === 'Culture' ? 'active' : ''" @click="toLink('/culture')">企业文化</li>
          <li :class="currRoute === 'News' ? 'active' : ''" @click="toLink('/news')">新闻动态</li>
          <li :class="currRoute === 'About' ? 'active' : ''" @click="toLink('/about')">关于我们</li>
        </ul>
      </div>
    </div>
    <div class="body">
      <router-view/>
    </div>
    <div class="footer">
      <div class="footer-wrapper">
        <div class="left">
          <div class="logo">
            <img src="@/assets/logo_footer.png" alt="海多普" title="海多普">
          </div>
          <div class="info-box">
            <p>联系电话：<span>0571-87990961</span><span>15355072532</span><span>（服务热线）</span></p>
            <p>邮箱：business@haidapu.cn</p>
            <p>地址：杭州市滨江区聚业路26号</p>
          </div>
        </div>
        <div class="scan">
          <div class="scan-box">
            <img src="@/assets/scan1.png">
            <span>公众号</span>
          </div>
          <div class="scan-box">
            <img src="@/assets/scan1.png">
            <span>小海客服</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currRoute: 'Home',
      currPath: '/'
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   // 在渲染该组件的对应路由被 confirm 前调用
  //   // 不！能！获取组件实例 `this`
  //   // 因为当守卫执行前，组件实例还没被创建
  //   console.log(to)
  //   next()
  // },
  // created() {
  //   if (this.$route.query.to) {
  //     this.toLink(this.$route.query.to)
  //   }
  // },
  watch: {
    '$route' (to) {
      let names = to.name.split(' ')
      this.currPath = to.path
      this.currRoute = names[0]
    }
  },
  methods: {
    toLink(path) {
      if (this.currPath === path) return
      
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss">
html, body {
  height: 100%;
  * {
    box-sizing: border-box;
  }
}
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
}
.header {
  width: 100%;
  height: 80px;
  background-color: #FFFFFF;
  .header-wrapper {
    width: 1200px;
    height: 80px;
    margin: 0 auto;
    .logo {
      float: left;
    }
    .nav {
      float: right;
      li {
        height: 80px;
        line-height: 70px;
        float: left;
        width: 122px;
        text-align: center;
        font-size: 18px;
        cursor: pointer;
        &.active {
          border-bottom: 10px solid #194898;
          color: #194898;
        }
      }
    }
  }
}
.body {
  min-height: calc(100% - 354px);
}
.footer {
  width: 100%;
  height: 174px;
  background-color: #1D2847;
  .footer-wrapper {
    width: 1120px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      width: 620px;
      height: 124px;
      display: flex;
      .logo {
        padding: 11px 0;
        padding-right: 48px;
        border-right: 1px solid #FFFFFF;
        img {
          display: block;
        }
      }
      .info-box {
        color: #FFFFFF;
        padding-left: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          span {
            &:nth-child(2) {
              margin-left: 20px;
            }
            &:nth-child(3) {
              font-size: 12px;
            }
          }
        }
      }
    }
    .scan {
      display: flex;
      width: 242px;
      justify-content: space-between;
      .scan-box {
        width: 100px;
        height: 134px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        img {
          display: block;
        }
        span {
          color: #FFFFFF;
          font-size: 14px;
        }
      }
    }
    
  }
}
.amap-logo { 
  display: none !important;
}
.amap-copyright {
  display: none !important;
}
</style>
