<template>
  <div class="toast" v-if="success">
    <svg t="1640770540620" v-if="type === 0" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2314" width="36" height="36">
      <path
        d="M122.2656 565.0432c-9.9328-9.9328-11.5712-25.3952-2.7648-36.4544l20.48-25.8048c8.3968-10.5472 24.064-13.2096 35.2256-5.8368l177.3568 118.3744c9.3184 6.2464 25.4976 5.4272 34.2016-1.6384L855.3472 233.472c10.5472-8.4992 27.0336-7.7824 36.2496 1.4336l11.5712 11.5712c10.0352 10.0352 9.3184 25.9072-1.2288 36.4544L398.9504 785.1008c-15.6672 15.6672-41.3696 14.9504-57.4464-1.1264L122.2656 565.0432z"
        fill="#ffffff"
        p-id="2315"
      ></path>
    </svg>
    <svg t="1640770765627" v-if="type === 1" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6644" width="36" height="36">
      <path
        d="M597.333333 213.333333c0 46.933333-38.4 85.333333-85.333333 85.333334s-85.333333-38.4-85.333333-85.333334 38.4-85.333333 85.333333-85.333333 85.333333 38.4 85.333333 85.333333zM597.333333 810.666667c0 46.933333-38.4 85.333333-85.333333 85.333333s-85.333333-38.4-85.333333-85.333333v-341.333334c0-46.933333 38.4-85.333333 85.333333-85.333333s85.333333 38.4 85.333333 85.333333v341.333334z"
        fill="#ffffff"
        p-id="6645"
      ></path>
    </svg>
    <div class="toast_text">{{ tipText }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      success: false,
      type: 0,
      tipText: '',
      timer: null
    }
  },
  methods: {
    show(type, msg) {
      this.timer && clearTimeout(this.timer)
      var _that = this
      this.type = type
      this.tipText = msg
      this.success = true
      this.timer = setTimeout(() => {
        _that.success = false
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: content-box;
  width: 88px;
  max-width: 70%;
  min-height: 88px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  text-align: center;
  word-break: break-all;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  z-index: 100;
  .toast_text {
    margin-top: 8px;
  }
}
</style>
