import axios from 'axios'
import * as urls from './urls'

const request = axios.create({
  baseURL: urls.baseUrl,
  timeout: 30 * 1000
})

// 请求拦截器
request.interceptors.request.use(
  config => {
    return config
  },
  err => {
    return Promise.reject(err)
  }
)
// 响应拦截器
request.interceptors.response.use(
  response => {
    return new Promise(resolve => {
      resolve(response.data)
    })
  },
  error => {
    console.error('请求出错:', error)
    return new Promise(resolve => {
      if (
        error.code === 'ECONNABORTED' &&
        error.message.indexOf('timeout') >= 0
      ) {
        resolve(false)
        return
      }
      resolve(false)
    })
  }
)

export default request
